import React from "react"

import {
  Box,
  ColorMode,
  FadeWrapper,
  AccordionGroup,
  Wrapper,
} from "components"

import PageSection from "./_pageSection"
import ScrollButton from "./_scrollButton"

const SummerStudents = ({ data }) => {
  return (
    <ColorMode mode="dark">
      <Box as="section" position="relative" py={["layout.6", "layout.7"]}>
        <Box
          position="absolute"
          top={0}
          left={[0, 32]}
          width={["100%", "calc(100% - 64px)"]}
          height="100%"
          bg="wash"
        />
        <FadeWrapper>
          <Wrapper position="relative">
            <PageSection
              data={{
                heading: data.heading,
                body: data.body,
              }}
            />
            {/* Summer Opportunities */}

            {data.summerOpportunities &&
              data.summerOpportunities[0] &&
              data.summerOpportunities[0].title && (
                <Box>
                  <AccordionGroup
                    items={data.summerOpportunities.map((job, index) => ({
                      id: job.title.text,
                      heading: job.title.text,
                      subheading: "",
                      children: (
                        <PageSection
                          data={{
                            body: job.description.text,
                            button_text: job.link_text,
                            button_link: job.link_url.url,
                            scrollLink: true,
                            key: "summerOpportunity" + index,
                          }}
                        />
                      ),
                    }))}
                  />
                </Box>
              )}

            {/* Summer Opportunities Default Text */}
            {(!data.summerOpportunities ||
              !data.summerOpportunities[0] ||
              !data.summerOpportunities[0].title) && (
              <>
                <PageSection
                  data={{
                    heading: data.emptyHeader,
                    body: data.emptyBody,
                    button_text: null,
                    button_link: null,
                  }}
                />
                {/* Default Scroll Button */}
                <ScrollButton id="career-form" label="Submit a Resume" />
              </>
            )}
          </Wrapper>
        </FadeWrapper>
      </Box>
    </ColorMode>
  )
}

export default SummerStudents
