import React from "react"
import { Box, AccordionGroup, FadeWrapper, Wrapper } from "components"

import PageSection from "./_pageSection"
import ScrollButton from "./_scrollButton"

const CurrentOpenings = ({ data }) => {
  return (
    <Box as="section" position="relative" py={["layout.6", "layout.7"]}>
      <Box
        position="absolute"
        top={0}
        left={[0, 32]}
        width={["100%", "calc(100% - 64px)"]}
        height="100%"
        bg="wash"
      />
      <FadeWrapper>
        <Wrapper position="relative">
          <PageSection
            data={{
              heading: data.heading,
              body: data.body,
            }}
          />
          {/* Job Opportunities */}

          {data.jobOpenings &&
            data.jobOpenings[0] &&
            data.jobOpenings[0].title && (
              <Box>
                <AccordionGroup
                  items={data.jobOpenings.map((job, index) => ({
                    id: job.title.text,
                    heading: job.title.text,
                    subheading: "",
                    children: (
                      <PageSection
                        data={{
                          body: job.description.text,
                          button_text: job.link_text,
                          button_link: job.link_url.url,
                          scrollLink: true,
                          key: "job" + index,
                        }}
                      />
                    ),
                  }))}
                />
              </Box>
            )}

          {/* Job Opportunities Default Text */}

          {(!data.jobOpenings ||
            !data.jobOpenings[0] ||
            !data.jobOpenings[0].title) && (
            <>
              <PageSection
                data={{
                  heading: data.emptyHeader,
                  body: data.emptyBody,
                  button_text: null,
                  button_link: null,
                }}
              />
              {/* Default Scroll Button */}
              <ScrollButton
                id="career-form"
                label="Submit a Resume"
              />
            </>
          )}
        </Wrapper>
      </FadeWrapper>
    </Box>
  )
}

export default CurrentOpenings
