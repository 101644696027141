import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { Box, PageHeader, SEO, GridRow, IconGrid } from "components"

import CollageSlice from "../../templates/page/_collageSlice"
import MapBlock from "../../templates/page/_mapBlock"
import RichTextSlice from "../../templates/page/_richTextSlice"
import SingleImageSlice from "../../templates/page/_singleImageSlice"

import CurrentOpenings from "./_currentOpenings"
import Collage from "./_collage"
import Form from "./_form"
import SummerStudents from "./_summerStudents"
import { Slices } from "./_slices"

function CareersPage() {
  const { page } = useStaticQuery(PAGE_QUERY)

  return (
    <>
      <SEO
        title={page.data.seo_title && page.data.seo_title.text}
        description={page.data.seo_description}
        imageOpenGraph={page.data.seo_image && page.data.seo_image.url}
        imageTwitter={page.data.seo_image && page.data.seo_image.url}
        imageAlt={page.data.seo_image && page.data.seo_image.alt}
      />
      {/* Page header */}
      <PageHeader
        data={{
          heading: page.data.subtitle && page.data.subtitle.html,
          description: page.data.subtitle_text && page.data.subtitle_text.html,
          image: page.data.hero_image,
          bottomBackground: "Gray",
        }}
      />
      {/* Current Openings Introduction. */}
      <CurrentOpenings
        data={{
          heading:
            page.data.current_openings_intro_heading &&
            page.data.current_openings_intro_heading.text,
          body:
            page.data.current_openings_intro_text &&
            page.data.current_openings_intro_text.html,
          jobOpenings: page.data.job_openings,
          emptyHeader:
            page.data.current_openings_body_heading &&
            page.data.current_openings_body_heading.text,
          emptyBody:
            page.data.current_openings_body_text &&
            page.data.current_openings_body_text.html,
          emptyButtonText: page.data.current_openings_body_button_text,
          emptyButtonLink:
            page.data.current_openings_body_button_link &&
            page.data.current_openings_body_button_link.url,
        }}
      />
      {/* Collage Block */}
      <Collage
        data={{
          primaryImage: page.data.collage_primary,
          secondaryImage: page.data.collage_secondary,
          topBackground: "Gray",
          bottomBackground: "Black",
        }}
      />
      {/* Summer Student Opportunities */}
      <SummerStudents
        data={{
          heading:
            page.data.summer_student_intro_heading &&
            page.data.summer_student_intro_heading.text,
          body:
            page.data.summer_student_intro_text &&
            page.data.summer_student_intro_text.html,
          summerOpportunities: page.data.summer_opportunities,
          emptyHeader:
            page.data.summer_student_body_heading &&
            page.data.summer_student_body_heading.text,
          emptyBody:
            page.data.summer_student_body_text &&
            page.data.summer_student_body_text.html,
          emptyButtonText: page.data.summer_student_body_button_text,
          emptyButtonLink:
            page.data.summer_student_body_button_link &&
            page.data.summer_student_body_button_link.url,
        }}
      />
      <Slices page={page} />
      {/* Contact Form */}
      <Box id="career-form">
        <Form
          data={{
            heading: "Submit a Resume",
          }}
        />
      </Box>
    </>
  )
}

const PAGE_QUERY = graphql`
  {
    page: prismicCareers {
      data {
        seo_title {
          text
        }
        seo_description
        seo_image {
          url
          alt
        }
        page_title {
          html
        }
        subtitle {
          html
        }
        subtitle_text {
          html
        }
        hero_image {
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        body {
          ... on PrismicCareersBodyGridRow {
            slice_type
            id
            primary {
              background_color
              heading {
                text
              }
              grid_text {
                html
              }
              button_label
              button_link {
                url
              }
              primary_image {
                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              secondary_image {
                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicCareersBodyText {
            slice_type
            primary {
              background_color
              text_box_width
              richtext {
                html
              }
              button_name
              button_url {
                url
              }
            }
          }
          ... on PrismicCareersBodyFactors {
            slice_type

            items {
              graphic {
                fluid(maxWidth: 192) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              heading {
                text
              }
              description {
                html
              }
            }
            primary {
              background_color
              heading {
                text
              }
            }
          }
          ... on PrismicCareersBodyCollage {
            slice_type
            primary {
              primary_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
              secondary_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicCareersBodyImageWithCaption {
            slice_type
            primary {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicCareersBodyMapBlock {
            slice_type
            items {
              operation_title {
                text
              }
              operation_description {
                html
              }
              operation_id
            }
          }
        }
        job_openings {
          description {
            text
          }
          link_url {
            url
          }
          title {
            text
          }
          link_text
        }
        summer_opportunities {
          description {
            text
          }
          link_text
          link_url {
            url
          }
          title {
            text
          }
        }
        collage_primary {
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        collage_secondary {
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }

        current_openings_body_button_text
        current_openings_body_button_link {
          url
        }
        current_openings_body_heading {
          text
        }
        current_openings_body_text {
          html
        }

        current_openings_intro_heading {
          text
        }
        current_openings_intro_text {
          html
        }

        summer_student_body_button_alt_text
        summer_student_body_button_text
        summer_student_body_button_link {
          url
        }
        summer_student_body_heading {
          text
        }
        summer_student_body_text {
          html
        }
        summer_student_intro_heading {
          text
        }
        summer_student_intro_text {
          html
        }
      }
    }
  }
`

export default CareersPage
