import React from "react"

import { useStaticQuery } from "gatsby"

import { GridRow, IconGrid } from "components"

import CollageSlice from "../../templates/page/_collageSlice"
import MapBlock from "../../templates/page/_mapBlock"
import RichTextSlice from "../../templates/page/_richTextSlice"
import SingleImageSlice from "../../templates/page/_singleImageSlice"

export function Slices({ page }) {
  return (
    <>
      {page.data.body &&
        page.data.body.length > 0 &&
        page.data.body.map(({ slice_type, primary, items }, index) => {
          switch (slice_type) {
            // Block of text with an optional header. Optional image below the text.
            case "text":
              return (
                <RichTextSlice
                  data={{
                    content: primary.richtext && primary.richtext.html,
                    backgroundColor: primary.background_color,
                    buttonLabel: primary.button_name,
                    buttonLink: primary.button_url,
                    width: primary.text_box_width,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "collage":
              return (
                <CollageSlice
                  data={{
                    primaryImage: primary.primary_image,
                    secondaryImage: primary.secondary_image,
                    topBackground:
                      page.data.body[index - 1] &&
                      page.data.body[index - 1].primary &&
                      page.data.body[index - 1].primary.background_color,
                    bottomBackground:
                      page.data.body[index + 1] &&
                      page.data.body[index + 1].primary &&
                      page.data.body[index + 1].primary.background_color,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "image_with_caption":
              return (
                <SingleImageSlice
                  data={{
                    image: primary.image,
                    topBackground:
                      page.data.body[index - 1] &&
                      page.data.body[index - 1].primary &&
                      page.data.body[index - 1].primary.background_color,
                    bottomBackground:
                      page.data.body[index + 1] &&
                      page.data.body[index + 1].primary &&
                      page.data.body[index + 1].primary.background_color,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "factors":
              return (
                <IconGrid
                  title={primary.heading.text}
                  data={{
                    items: items,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "grid_row":
              return (
                <GridRow
                  data={{
                    heading: primary.heading.text,
                    body: primary.grid_text.html,
                    button: primary.button_label,
                    buttonLink: primary.button_link && primary.button_link.url,
                    imageOne: primary.primary_image,
                    imageTwo: primary.secondary_image,
                    topBackground:
                      page.data.body[index - 1] &&
                      page.data.body[index - 1].primary &&
                      page.data.body[index - 1].primary.background_color,
                    bottomBackground:
                      page.data.body[index + 1] &&
                      page.data.body[index + 1].primary &&
                      page.data.body[index + 1].primary.background_color,
                    index: index,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "map_block":
              return (
                <MapBlock
                  data={{
                    items: items,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            default:
              return null
          }
        })}
    </>
  )
}
