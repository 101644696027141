import React from "react"

import { Box, Button, Heading, RichText } from "components"

import ScrollButton from "./_scrollButton"

const PageSection = ({ data }) => (
  <Box mb="layout.6" maxWidth={1080 / 1.5}>
    {data.heading && <Heading mb={6} children={data.heading} />}
    {data.body && <RichText content={{ html: data.body }} />}
    {data.button_text && (
      <Button as="a" href={data.button_link} mt="layout.4">
        {data.button_text}
      </Button>
    )}

    {data.scrollLink && (
      <Box mt="layout.3">
        <ScrollButton id="career-form" label="Apply Now" />
      </Box>
    )}
  </Box>
)

export default PageSection
