import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { Button } from "components"

function ScrollButton({ id, label }) {
  return (
    <ScrollLink smooth={true} to={id}>
      <Button children={label} />
    </ScrollLink>
  )
}

export default ScrollButton
