import React, { useState } from "react"
import css from "@styled-system/css"

import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Tabs,
  Text,
  Wrapper,
} from "components"

function Form({ data }) {
  const [email, setEmail] = useState("")
  const [fileReady, setFileReady] = useState(false)
  return (
    <Box bg="wash" width={["100%", "calc(100% - 64px)"]} mx="auto">
      <Wrapper py={["layout.7", "layout.8"]}>
        {/* Heading Text */}

        {data.heading && (
          <Heading children={data.heading} mb={["layout.2", "layout.4"]} />
        )}
        <Box p="layout.4" bg="secondary" my={["layout.2", "layout.4"]}>
          <Text color="white">
            We have been informed that Teine Energy has recently been impacted
            by a phishing scam in which individuals are contacted by someone
            impersonating Teine Energy and requesting that they submit their
            resume. Teine Energy does not solicit personal information and is
            not actively reaching out to potential candidates. Please do not
            open any links or attachments and reply to any such communication
            you may have received in error.
          </Text>
        </Box>
        <Tabs
          tabs={[
            {
              label: null,
              content: (
                <Box
                  mt="layout.4"
                  name="careersForm"
                  encType="multipart/form-data"
                  method="POST"
                  as="form"
                  action="/thank-you/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={e => {
                    if (!email || email === "ismond6@gmail.com") {
                      e.preventDefault()
                    }
                  }}
                >
                  <input type="hidden" name="form-name" value="careersForm" />
                  <Text mb="layout.2">
                    We appreciate the interest of all applicants, however, only
                    those candidates to be interviewed will be contacted.
                  </Text>

                  {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
                  <Flex
                    flexWrap="wrap"
                    m={-16}
                    css={css({
                      "> *": {
                        width: ["100%", null, "50%"],
                        p: 16,
                      },
                    })}
                  >
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-jobTitle"
                        name="jobTitle"
                        label="Job Title"
                        required="true"
                        placeholder="Job title you're applying for."
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-name"
                        name="name"
                        label="Name"
                        required="true"
                        placeholder="Your full name"
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-email"
                        name="email"
                        label="Email"
                        required="true"
                        placeholder="Your email"
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-phone"
                        name="phone"
                        label="Phone"
                        type="tel"
                        required="true"
                        placeholder="Your phone number"
                      />
                    </Box>
                    <Box>
                      <Heading size={100} mb="layout.1">
                        Resume
                      </Heading>
                      <Text as="div">
                        <input
                          type="file"
                          name="resume"
                          required="true"
                          // onChange={() => {
                          //   setFileReady(true)
                          // }}
                        />
                      </Text>
                    </Box>
                    {/* <Box>
                      <Heading size={100} mb="layout.1">
                        Additional Documents (OPTIONAL)
                      </Heading>
                      <Text as="div">
                        <input
                          type="file"
                          name="additionalDocuments"
                          // onChange={() => {
                          //   setFileReady(true)
                          // }}
                        />
                      </Text>
                    </Box> */}
                  </Flex>
                  <Box mt={["layout.2", "layout.4"]}>
                    <Button
                      children="Submit inquiry"
                      type="submit"
                      disabled={email === "ismond6@gmail.com"}
                    />
                  </Box>
                </Box>
              ),
            },
          ]}
        />
      </Wrapper>
    </Box>
  )
}

export default Form
